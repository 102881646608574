<script>
export default {
  name: "RouteView",
  data() {
    return {}
  },
  render() {
    const notKeep = <router-view />

    return notKeep
  }
}
</script>
